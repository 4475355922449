import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { getReportService } from 'services/services';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import pLimit from 'p-limit';
import { useCallback, useMemo } from 'react';
import { IReportDataST } from 'codegen/report';
import { transformReportData } from '../utils/transformReportData';

const requestPageSize = NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB;
const REPORT_SUMMARY_QUERY_KEY = 'report-summary';
const REPORT_LOCATIONS_DATA_QUERY_KEY = 'report-locations-data';

const MAX_CONCURRENT_REQUESTS = 10;
const limit = pLimit(MAX_CONCURRENT_REQUESTS);

export const useReportData = ({ systemId, reportId }: { systemId: string; reportId: string }) => {
  const queryClient = useQueryClient();

  const invalidateReportLocationsData = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [REPORT_LOCATIONS_DATA_QUERY_KEY],
    });
  }, [queryClient]);

  const { data: reportSummary, isLoading: isLoadingSummary } = useQuery({
    queryKey: [REPORT_SUMMARY_QUERY_KEY, reportId],
    queryFn: () => getReportService().getReportSummary(systemId, reportId),
    select: (data) => data.data,
  });

  const locations = reportSummary?.item?.locations || [];
  const requestChunk: { fromLocation: string; toLocation: string }[] = [];

  const getToLocation = (i: number) =>
    i + requestPageSize - 1 > locations.length
      ? locations[locations.length - 1]
      : locations[i + requestPageSize - 1];

  for (let i = 0; i < locations.length; i += requestPageSize) {
    requestChunk.push({
      fromLocation: locations[i],
      toLocation: getToLocation(i),
    });
  }

  const allLocationsQuery = useQueries({
    queries: requestChunk.map((chunk) => ({
      queryKey: [REPORT_LOCATIONS_DATA_QUERY_KEY, chunk.fromLocation],
      queryFn: () =>
        limit(() =>
          getReportService().getReportData(
            systemId,
            reportId,
            chunk.fromLocation,
            chunk.toLocation,
          ),
        ),

      staleTime: Infinity,
    })),
  });

  const isLoadingLocations = allLocationsQuery.some((result) => result.isFetching);

  // Note: here we subdived the requests in segments of n requests
  // we will use this to update the locationData only when a segement finishes.
  // The reason for this is that updating locationData at each request causes performance
  // issues (especially in weak hardware), however updating it only when all requests are don
  // gives the impression that the dashboard is stuck. (https://verity-ag.atlassian.net/browse/PI-1525)
  // eno: 2024-11-14
  const requestsSegment = Math.floor(
    allLocationsQuery.filter((query) => query.isSuccess).length / 8,
  );

  const locationData = useMemo(
    () =>
      allLocationsQuery
        .map((result) =>
          Object.values(result.data?.data ?? {})
            .filter(Boolean)
            .flatMap((data: IReportDataST) => transformReportData(data)),
        )
        .flat(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingLocations, requestsSegment],
  );

  return {
    locationData,
    isLoadingReportLocations: isLoadingLocations || isLoadingSummary,
    invalidateReportLocationsData,
  };
};
