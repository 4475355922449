import type CameraControlsImpl from 'camera-controls';
import { Box3, Group, Vector3, MathUtils, Object3DEventMap } from 'three';
import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';

const FIT_PADDING = {
  paddingTop: 1,
  paddingRight: 1,
  paddingBottom: 1,
  paddingLeft: 1,
};

export interface Fit {
  type: 'width' | 'height';
}
export function useZoomFit({
  aisleRef,
  current,
  fit,
}: {
  aisleRef: React.RefObject<Group<Object3DEventMap>>;

  current?: {
    normal: Vector3;
    position: Vector3;
  };
  /**
   * `fit` is made to be and object instead of a primitive
   * value on purpose here. This is for fit to work even after zoom
   */
  fit?: Fit;
}) {
  const controls = useThree((state) => state.controls);
  useEffect(() => {
    if (controls && aisleRef.current) {
      const cameraControls = controls as unknown as CameraControlsImpl;

      const aisleBox = new Box3().setFromObject(aisleRef.current);
      let center = aisleBox.getCenter(new Vector3());
      const dimensions = aisleBox.getSize(new Vector3());

      if (current) {
        center = new Vector3(current.position.x, current.position.y, center.z);
      }

      const verticalBox = new Box3();
      verticalBox.setFromCenterAndSize(center, new Vector3(1, 1, dimensions.z));

      const aisleDirection = dimensions.x > dimensions.y ? 'x' : 'y';

      const cameraOffset =
        aisleDirection === 'y'
          ? {
              x: dimensions.y,
              y: 0,
            }
          : {
              x: 0,
              y: dimensions.x,
            };

      cameraControls.setLookAt(
        center.x + cameraOffset.x,
        center.y + cameraOffset.y,
        center.z,
        center.x,
        center.y,
        center.z,
        false,
      );

      const fitBox = fit?.type === 'width' ? aisleBox : verticalBox;
      cameraControls.setBoundary(aisleBox);

      cameraControls.fitToBox(fitBox, false, FIT_PADDING);
      if (current && (current.normal.x === -1 || current.normal.y === -1)) {
        cameraControls.rotate(MathUtils.degToRad(180), 0, false);
      }
    }
  }, [aisleRef, controls, current, fit]);
}
