import { useEffect, useState } from 'react';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { Grid } from '@mui/material';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { formatNumberWithSeparators } from 'utils/numberFormatting';
import { Box } from 'components/common/Box';
import { WarehouseStatsBar } from './features/warehouse-stats-bar/WarehouseStatsBar';
import { WarehouseStatsPie } from './features/warehouse-stats-pie/WarehouseStatsPie';
import { WarehouseStatsWidgetToggle } from './WarehouseStatsWidgetToggle';
import { ChartType, WarehouseStatsDataSerie } from './WarehouseStats.model';

const DEFAULT_MODE: ChartType = 'progress';

const validateMode = (mode: ChartType, fallback: ChartType): ChartType => {
  const validModes: ChartType[] = ['pie', 'progress'];

  if (validModes.includes(mode)) {
    return mode;
  }

  return fallback;
};

export const WarehouseStatsWidget = ({
  id,
  title,
  subTitle,
  unit,
  unitPrefix,
  data,
  counter,
  colors = ['red', 'blue', 'green', 'yellow', 'orange', 'grey', 'pink'],
  cols,
  onGridStateSelect,
  initialMode = DEFAULT_MODE,
}: {
  id: string;
  title: string;
  subTitle?: string;
  unit: string;
  unitPrefix?: string;
  data: WarehouseStatsDataSerie[];
  colors: string[];
  cols: 3 | 4 | 6;
  counter: number;
  onGridStateSelect?: (state: GridInitialStatePremium) => void;
  initialMode?: ChartType;
}) => {
  const localStorageKey = `warehouse-stats-widget-${id}`;

  const [mode, setMode] = useState<ChartType>(() =>
    validateMode(localStorage.getItem(localStorageKey) as ChartType, initialMode),
  );

  const handleUpdateMode = (chartType: ChartType) => {
    setMode(chartType);
  };

  useEffect(() => {
    localStorage.setItem(localStorageKey, mode);
  }, [mode, localStorageKey]);

  return (
    <BaseCard
      cardFor="warehouse-stats"
      dataTestId="c-warehouse-stat-card"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      title={
        <>
          {title}
          {'  '}
          <WarehouseStatsWidgetToggle mode={mode} onToggle={handleUpdateMode} />
        </>
      }
      subtitle={subTitle}
      counter={formatNumberWithSeparators(counter)}
      counterSubject={unitPrefix ? `${unitPrefix} ${unit}` : unit}
      hideCounterSubject={true}
    >
      {mode === 'progress' && (
        <Grid container spacing={2.5}>
          {data
            .filter((serie) => !!serie.value)
            .map((serie, index) => (
              <WarehouseStatsBar
                key={`${serie.label}_${serie.id}`}
                cols={12}
                label={serie.label}
                description={serie.description}
                total={counter}
                unit={unit}
                showProgressBar={true}
                value={serie.value}
                color={colors[index]}
                onGridStateSelect={onGridStateSelect}
                gridState={serie.gridState}
              />
            ))}
        </Grid>
      )}

      {mode === 'pie' && (
        <Box alignItems="center">
          <Box width="100%" justifyContent="center" display="flex">
            <WarehouseStatsPie colors={colors} data={data} />
          </Box>

          <Grid container spacing={2.5}>
            {data.map((serie, index) => {
              if (serie.value) {
                return (
                  <WarehouseStatsBar
                    key={`${serie.label}_${serie.id}`}
                    cols={cols}
                    label={serie.label}
                    description={serie.description}
                    total={counter}
                    value={serie.value}
                    unit={unit}
                    color={colors[index]}
                    showProgressBar={false}
                    gridState={serie.gridState}
                    onGridStateSelect={onGridStateSelect}
                  />
                );
              }
              return null;
            })}
          </Grid>
        </Box>
      )}
    </BaseCard>
  );
};
