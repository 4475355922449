import { Alert, AlertTitle } from '@mui/material';
import { AlertColor, AlertProps } from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import { ReactElement } from 'react';
import { Box } from './Box';

export interface IBasicAlertProps extends Omit<AlertProps, 'severity'> {
  isActive: boolean;
  status: AlertColor;
  message: string;
  action?: ReactElement;
  testId?: string;
}

export const BasicAlert = ({
  isActive,
  status,
  icon,
  title,
  message,
  action,
  testId,
  iconMapping,
}: IBasicAlertProps) => {
  if (!isActive) return null;

  return (
    <Fade in={isActive}>
      <Box>
        <Alert
          data-testid={testId}
          iconMapping={iconMapping}
          icon={icon}
          action={action}
          severity={status}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {message || ''}
        </Alert>
      </Box>
    </Fade>
  );
};
