import { ActionsMenu, ActionMenuOptions } from 'components/common/ActionsMenu';

import { useFacilityModalsStore } from 'store/Modals';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { useCallback } from 'react';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { Switch } from '@mui/material';
import { LocationImagesState } from '../../hooks/useLocationImagesState';

export const ThreeDotsMenu = ({
  locationData,
  enableSnooze,
  refreshLocationData,
  facilitySettings,
  locationImagesState,
}: {
  locationData: ILocationData;
  enableSnooze: boolean;
  refreshLocationData: (locationLabel: string) => void;
  facilitySettings: IFacilitySettingsST;
  locationImagesState: LocationImagesState;
}) => {
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const {
    generateAndDownloadZip,
    isDownloadingImages,
    toggleBarcodeHighlighting,
    showBarcodeHighlighting,
  } = locationImagesState;

  const showSnooze =
    enableSnooze &&
    locationData?.rowData?.actions?.actions?.length &&
    locationData.rowData.actions.actions[0].label === 'Snooze' &&
    !locationData?.rowData.actions?.actions[0].disabled;
  const showUnsnooze =
    enableSnooze &&
    locationData?.rowData?.actions?.actions?.length &&
    locationData?.rowData.actions?.actions[0]?.label === 'Unsnooze' &&
    !locationData?.rowData.actions?.actions[0]?.disabled;

  const toggleSnoozeModal = useCallback(() => {
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.TOGGLE_SNOOZE_ISSUE,
      payload: locationData.rowData?.actions?.data,
      refreshData: {
        refreshData: () => refreshLocationData(locationData.location),
      },
    });
  }, [
    dispatchFacilityModals,
    locationData.location,
    locationData.rowData?.actions?.data,
    refreshLocationData,
  ]);

  const menuOptions: ActionMenuOptions[] = [
    {
      id: crypto.randomUUID(),
      label: 'Download images',
      clickHandler: generateAndDownloadZip,
      disabled: isDownloadingImages || !locationData.imageIds?.length,
    },
  ];

  if (showSnooze || showUnsnooze) {
    menuOptions.push({
      id: crypto.randomUUID(),
      label: showSnooze ? 'Snooze' : 'Unsnooze',
      clickHandler: () => toggleSnoozeModal(),
    });
  }

  if (facilitySettings.enable_barcode_highlighting) {
    menuOptions.push({
      id: crypto.randomUUID(),
      label: (
        <>
          Highlight Barcodes in gallery
          <Switch
            checked={showBarcodeHighlighting}
            inputProps={{ 'aria-label': 'toggle switch' }}
            size="small"
          />
        </>
      ),
      clickHandler: toggleBarcodeHighlighting,
    });
  }

  return <ActionsMenu options={menuOptions} size="large" />;
};
