import { Button, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useRef } from 'react';
import { FormInput } from 'components/FormFields/FormInput';
import { FormInputDistances } from 'components/FormFields/FormInputDistances/FormInputDistances';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { transformZoneSizeAndPosition } from 'udb/ground-control/utils/transformZoneSizeAndPosition';
import { useStyles } from './styles';
import { NoFlyZoneFormProps } from './noFlyZoneForm.model';
import { useValidateDroneZoneForm } from '../hooks/useValidadeDroneZoneForm';

export const NoFlyZoneForm = ({
  noFlyZone,
  worldBox,
  onCancel,
  onUpdate,
  onSubmit,
}: NoFlyZoneFormProps) => {
  const { classes } = useStyles();

  const originalNoFlyZone = useRef(noFlyZone);
  const formValues = useRef<FormikProps<NoFlyZone>>(null);
  const { validate } = useValidateDroneZoneForm('no-fly');

  const {
    minX: facilityMinX,
    maxX: facilityMaxX,
    minY: facilityMinY,
    maxY: facilityMaxY,
    minZ: facilityMinZ,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(worldBox);

  const maxHeight = facilityMaxZ - facilityMinZ;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let fieldName = e.target.name as keyof NoFlyZone;
    let fieldValue: string | number | object = e.target.value;

    const isSizeOrPosition = fieldName.includes('size') || fieldName.includes('position');

    const droneZoneFormValues = formValues?.current?.values;

    if (isSizeOrPosition) {
      const fieldNameSplit = fieldName.split('.');
      fieldName = fieldNameSplit[0] as keyof NoFlyZone;
      const keyName = fieldNameSplit[1] as keyof NoFlyZone['sizeAndPosition'];

      fieldValue = {
        ...(droneZoneFormValues?.sizeAndPosition ?? {}),
        [keyName]: Number(fieldValue),
      };
    }

    if (droneZoneFormValues) {
      onUpdate({ ...droneZoneFormValues, [fieldName]: fieldValue });
    }
  };

  const handleSubmit = (zoneValues: NoFlyZone) => {
    const transformedZoneValues = transformZoneSizeAndPosition(zoneValues);
    return onSubmit(transformedZoneValues);
  };

  const inputProps = { step: 0.5, min: 1 };

  return (
    <Formik
      enableReinitialize
      initialValues={noFlyZone}
      innerRef={formValues}
      onSubmit={handleSubmit}
      validate={validate(worldBox)}
    >
      {({ values, setFieldError, isValid, isSubmitting }) => (
        <Form noValidate className={classes.form}>
          <Typography variant="h1" className={classes.formHeader}>
            No-fly zone details
          </Typography>

          <div className={classes.formBody}>
            <FormInput
              name="name"
              label="No-fly zone name"
              required
              onChange={handleChange}
              fullWidth
            />

            <FormInput
              name="description"
              label="Description"
              multiline
              fullWidth
              onChange={handleChange}
            />

            <FormInputDistances
              inputProps={{ ...inputProps, max: maxHeight }}
              name="sizeAndPosition.h"
              label="Height"
              onChange={handleChange}
              fullWidth
              isMaxVisible={true}
              initialValue={noFlyZone.sizeAndPosition.h}
              setFieldError={(errorMessage) => setFieldError('sizeAndPosition.h', errorMessage)}
            />

            <FormInputDistances
              name="sizeAndPosition.w"
              label="Width"
              inputProps={{
                ...inputProps,
                max: facilityMaxX - values.sizeAndPosition.minX,
              }}
              fullWidth
              onChange={handleChange}
              initialValue={noFlyZone.sizeAndPosition.w}
              setFieldError={(errorMessage) => setFieldError('sizeAndPosition.w', errorMessage)}
            />

            <FormInputDistances
              name="sizeAndPosition.l"
              label="Length"
              inputProps={{
                ...inputProps,
                min: 0,
                max: facilityMaxY - values.sizeAndPosition.minY,
              }}
              fullWidth
              onChange={handleChange}
              initialValue={noFlyZone.sizeAndPosition.l}
              setFieldError={(errorMessage) => setFieldError('sizeAndPosition.l', errorMessage)}
            />

            <div className={classes.formSection}>
              <FormInputDistances
                name="sizeAndPosition.minX"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinX,
                  max: facilityMaxX - values.sizeAndPosition.w,
                }}
                onChange={handleChange}
                label="X-Position"
                fullWidth
                initialValue={noFlyZone.sizeAndPosition.minX}
                setFieldError={(errorMessage) =>
                  setFieldError('sizeAndPosition.minX', errorMessage)
                }
              />

              <FormInputDistances
                name="sizeAndPosition.minY"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinY,
                  max: facilityMaxY - values.sizeAndPosition.l,
                }}
                fullWidth
                onChange={handleChange}
                label="Y-Position"
                initialValue={noFlyZone.sizeAndPosition.minY}
                setFieldError={(errorMessage) =>
                  setFieldError('sizeAndPosition.minY', errorMessage)
                }
              />
            </div>
          </div>

          <div className={classes.formFooter}>
            <Button
              variant="outlined"
              sx={{ flex: 1, mr: 1 }}
              onClick={() => onCancel(originalNoFlyZone.current)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              sx={{ flex: 1 }}
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
