import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from 'tss-react/mui';
import { useTheme, Theme, Tabs, Tab } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { Box } from 'components/common/Box';
import { QueryNames } from 'ts-types/QueryNames';
import { getWmsRawData } from './api/getLocationWMSRawData';
import { WMSRawValue } from './WMSRawValue';

const useStyles = (height: string) =>
  makeStyles()((theme: Theme) => ({
    wmsRawDataDiv: {
      height,
      marginBottom: '-24px',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: 0,
      paddingTop: 20,
      '&::-webkit-scrollbar': {
        height: 9,
        width: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#BDBDBD',
        borderRadius: 200,
      },
    },
    table: {
      display: 'grid',
      gridTemplateColumns: '1fr 4fr',
      gap: '15px 50px',
      paddingLeft: '10px',
      width: '100%',
    },
    wmsTabs: {
      paddingLeft: '10px',
    },
    emptyCard: {
      textAlign: 'center',
      margin: theme.spacing(5, 0),
      minHeight: theme.spacing(4),
    },
    key: {
      textAlign: 'left',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '150%',
      fontWeight: 400,
      color: 'var(--Text-Primary, rgba(0, 0, 0, .87))',
      letterSpacing: '0.15px',
    },
    value: {
      textAlign: 'left',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '150%',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      fontWeight: 500,
      color: 'var(--Text-Primary, rgba(0, 0, 0, 1))',
      letterSpacing: '0.1px',
      maxWidth: '100%',
      whiteSpace: 'normal',
    },
  }));

export const WMSRawData = (props: {
  slotLabel: string;
  systemId: string;
  wmsVersion: number | null;
  height: string;
}) => {
  const { systemId, slotLabel, wmsVersion, height } = props;
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(0);

  const { isLoading, data = {} } = useQuery({
    queryKey: [QueryNames.LOCATION_MODAL_GET_RAW_WMS_DATA, slotLabel],
    queryFn: () => getWmsRawData(systemId, slotLabel, wmsVersion),
    select: ({ data }) => data,
  });

  const { classes } = useStyles(height)();
  const tabKeys = Object.keys(data);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    <BaseCard
      cardFor="wms_data"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      isLoading={isLoading}
      sx={{ border: '1px solid', borderColor: theme.palette.grey[200] }}
      title="WMS Data"
      subtitle="Raw Data pulled from WMS."
      scrollable
    >
      {tabKeys.length > 0 ? (
        <>
          {tabKeys.length > 1 && (
            <Tabs
              className={classes.wmsTabs}
              value={activeTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabKeys.map((key) => (
                <Tab label={key} key={key} />
              ))}
            </Tabs>
          )}
          <Box className={classes.wmsRawDataDiv}>
            <div className={classes.table}>
              {Object.entries((data[tabKeys[activeTab]] as Record<string, any>) || {}).map(
                ([name, value]) => (
                  <WMSRawValue key={name} name={name} value={value} classes={classes} />
                ),
              )}
            </div>
          </Box>
        </>
      ) : (
        <Typography
          className={classes.emptyCard}
          variant="subtitle1"
          color="textSecondary"
          component="p"
        >
          No WMS raw data for this location
        </Typography>
      )}
    </BaseCard>
  );
};
