import Skeleton from '@mui/material/Skeleton';
import { Box } from 'components/common/Box';
import { AisleSummary } from 'shared/map-3d/aisle-summary/AisleSummary';
import { AisleView } from 'shared/map-3d/aisle-view/AisleView';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { useAisleView } from 'shared/map-3d/aisle-view/hooks/useAisleView';
import { Map3DCanvas } from 'shared/map-3d/map-3d-canvas/Map3DCanvas';
import { useMemo, useRef } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { ZoomControls } from 'shared/map-3d/zoom-controls/ZoomControls';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { BinHighlight } from 'shared/map-3d/bin-highlight/BinHighlight';
import { AisleViewScene } from 'shared/map-3d/aisle-view/features/AisleViewScene';
import { Group, Vector3 } from 'three';
import { useZoomToggle } from 'shared/map-3d/hooks/useZoomToggle';
import { useLocationData } from 'udb/inventory/features/warehouse-status-beta/hooks/useLocationData';
import { LocationDetailsDrawer } from 'shared/map-3d/location-details-drawer/LocationDetailsDrawer';
import { useLocationDetails } from 'shared/map-3d/hooks/useLocationDetails';
import { useMap3DCard } from './map3DCard.styles';
import { useActiveBinSelection } from '../../../../../shared/map-3d/hooks/useActiveBinSelection';

export const Map3DCard = ({
  systemId,
  currentLocationName,
  issueLogic,
}: {
  systemId: string;
  currentLocationName: string;
  issueLogic?: IRuleActionSTIssueLogicEnum;
}) => {
  const { classes } = useMap3DCard();
  const { data: facilityMap, isLoading } = useFacilityMap(systemId ?? '');
  const { locationDataMap } = useLocationData(systemId);
  const {
    aisleBins,
    current,
    summary = [],
  } = useAisleView({
    mapNode: facilityMap,
    locationDataMap,
    currentLocationName,
  });

  const { activeBinName, aisleBinsWithHandlers, resetActiveBin } = useActiveBinSelection(aisleBins);

  const selected3dBin = useMemo(
    () => aisleBins.find((bin) => bin.name === activeBinName),
    [activeBinName, aisleBins],
  );

  const details = useLocationDetails({
    activeBinName,
    issueLogic,
    locationDataMap,
  });
  const contentWrapperRef = useRef(null);
  const aisleRef = useRef<Group>(null);
  const { zoomType, handleZoomChange } = useZoomToggle();

  return (
    <Card>
      <CardHeader
        title="Neighboring locations"
        subheader="See surrounding issues and navigate to other locations"
      />

      <CardContent className={classes.cardContent}>
        <Box ref={contentWrapperRef} className={classes.canvasWrapper}>
          {!locationDataMap?.size || isLoading ? (
            <Skeleton height="100%" />
          ) : (
            <>
              <Map3DCanvas className={classes.canvas}>
                <AisleView bins={aisleBinsWithHandlers} ref={aisleRef} />
                <AisleViewScene
                  aisleRef={aisleRef}
                  fit={zoomType}
                  current={current as { normal: Vector3; position: Vector3 }}
                />
                {selected3dBin?.position && selected3dBin?.normal ? (
                  <BinHighlight
                    normal={selected3dBin.normal}
                    position={selected3dBin.position}
                    scale={selected3dBin.scale}
                  />
                ) : null}
              </Map3DCanvas>

              <AisleSummary className={classes.aisleSummary} issues={summary} />
              <ZoomControls className={classes.zoomControls} onZoom={handleZoomChange} />

              <LocationDetailsDrawer
                open={!!activeBinName && !!selected3dBin}
                containerRef={contentWrapperRef}
                details={details}
                onClose={resetActiveBin}
                locationName={activeBinName}
              />
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

Map3DCard.displayName = 'Map3DCard';
