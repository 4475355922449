import { useQueries, useQueryClient } from '@tanstack/react-query';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { useCallback, useMemo } from 'react';
import { useSlots } from 'hooks';
import pLimit from 'p-limit';
import { warehouseServices } from 'services/WarehouseServices';
import { ILocationDataST } from 'codegen/warehouse_status';
import { transformLocationData } from '../utils/transformLocationData';

const LOCATIONS_DATA_QUERY_KEY = 'load-all-locations-data';

const requestPageSize = NETWORK_REQUEST_SETTINGS.WAREHOUSE_PAGE_SIZE_FOR_FULL_REPORT_TAB;

const MAX_CONCURRENT_REQUESTS = 10;
const limit = pLimit(MAX_CONCURRENT_REQUESTS);

export const useLocationData = (systemId: string) => {
  const { data: slots = [] } = useSlots(systemId);

  const queryClient = useQueryClient();

  const reLoadLocationData = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [LOCATIONS_DATA_QUERY_KEY],
    });
  }, [queryClient]);

  const requestSlots = [];
  for (let i = 0; i < slots.length; i += requestPageSize) {
    requestSlots.push(slots[i]);
  }

  const allLocationsQuery = useQueries({
    queries: requestSlots.map((startSlotName) => ({
      queryKey: [LOCATIONS_DATA_QUERY_KEY, startSlotName],
      queryFn: () =>
        limit(() => warehouseServices.getLocationsData(systemId, startSlotName, requestPageSize)),
      staleTime: Infinity,
    })),
  });

  const isLoadingLocations = allLocationsQuery.some((result) => result.isFetching);
  // Note: here we subdived the requests in segments of n requests
  // we will use this to update the locationData only when a segement finishes.
  // The reason for this is that updating locationData at each request causes performance
  // issues (especially in weak hardware), however updating it only when all requests are don
  // gives the impression that the dashboard is stuck. (https://verity-ag.atlassian.net/browse/PI-1525)
  // eno: 2024-11-14
  const requestsSegment = Math.floor(
    allLocationsQuery.filter((query) => query.isSuccess).length / 8,
  );

  const locationDataMap = useMemo<Map<string, ILocationDataST>>(() => new Map(), []);

  const locationData = useMemo(
    () =>
      allLocationsQuery
        .map((result) =>
          Object.values(result.data?.data ?? {})
            .filter(Boolean)
            .map((data) => {
              locationDataMap.set(data.slot_label, data);
              return transformLocationData(data);
            }),
        )
        .flat(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingLocations, requestsSegment],
  );

  const invalidateLocationQuery = useCallback(
    (slotName: string) => {
      const slotIndex = slots.findIndex((s) => s === slotName);
      const batchIndex = Math.floor(slotIndex / 500) * 500;

      const startSlotName = slots[batchIndex];
      queryClient.invalidateQueries({
        queryKey: [LOCATIONS_DATA_QUERY_KEY, startSlotName],
      });
    },
    [queryClient, slots],
  );

  return useMemo(
    () => ({
      loadLocationData: reLoadLocationData,
      locationData,
      locationDataMap,
      isLoadingLocations,
      slots,
      invalidateLocationQuery,
    }),
    [
      reLoadLocationData,
      locationData,
      isLoadingLocations,
      slots,
      invalidateLocationQuery,
      locationDataMap,
    ],
  );
};
