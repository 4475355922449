import { useMutation } from '@tanstack/react-query';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ModalConfirm } from 'components/ModalsAndPopups/ModalConfirm';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { useSnackbar } from 'notistack';
import { WarehouseStatusOverview } from 'udb/inventory/features/warehouse-status/warehouse-status-overview/WarehouseStatusOverview';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserLevelStore } from 'store/UserLevelStore/userLevelStore';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { AlertComponent } from 'components/common/Alert';
import { useInfoBannersStorage } from 'hooks/useInfoBannersStorage';
import { MutationNames } from 'ts-types/MutationNames';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { useStyles } from './styles/WarehouseStatusBeta.styles';
import { sendWarehouseExportEmail as sendWarehouseExportEmailAPI } from './api/sendWarehouseExportEmail.api';
import { WarehouseStatusBetaTable } from './features/table/WarehouseStatusBetaTable';

export const WarehouseStatusBeta = () => {
  const { cx, classes } = useStyles();

  const { systemId = '' } = useParams();

  const [gridState, setGridState] = useState<GridInitialStatePremium | undefined>(undefined);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const isVisibleDownloadButton = userHasPermission(PERMISSION.EXPORT_WAREHOUSE_STATUS_VIA_EMAIL);

  const { stateUserLevel } = useUserLevelStore();

  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: sendWarehouseExportEmail, isPending: isDownloading } = useMutation({
    mutationFn: () => sendWarehouseExportEmailAPI(systemId),
    mutationKey: [MutationNames.SEND_WAREHOUSE_EXPORT_MAIL],
    onSuccess: () => {
      enqueueSnackbar('The warehouse status export shall be in your inbox in a few minutes.', {
        variant: 'success',
      });
      setIsDownloadModalOpen(false);
    },
    onError: () => {
      enqueueSnackbar('The Warehouse Status could not be sent by email.', {
        variant: 'error',
      });
    },
  });

  const { infoBanners, updateCountingInfoBannerStatus } = useInfoBannersStorage();

  return (
    <>
      <PageHeaderSection
        title="BETA Warehouse status"
        onClickDownload={() => setIsDownloadModalOpen(true)}
        downloadSpinning={isDownloading}
        showDownloadBtn={isVisibleDownloadButton}
      />

      <div className={classes.wrapper}>
        <div className={cx(classes.section, classes.sectionFullWidth)}>
          <AlertComponent
            isActive={
              !infoBanners?.countingIntegration.warehouseBetaStatusSeen &&
              !!facilitySettings.enable_counting
            }
            iconMapping={{
              info: <InfoOutlinedIcon fontSize="inherit" />,
            }}
            status="info"
            message="Please note that Counting report data is not available in the Warehouse Status view."
            preventClose={false}
            handleClose={() => updateCountingInfoBannerStatus('warehouseBetaStatusSeen', true)}
          />
        </div>
        <div className={cx(classes.section, classes.sectionColumns)}>
          <WarehouseStatusOverview systemId={systemId} onGridStateSelect={setGridState} />
        </div>
        <div className={cx(classes.section, classes.sectionFullWidth)}>
          <WarehouseStatusBetaTable gridState={gridState} />
        </div>
      </div>

      {isDownloadModalOpen && (
        <ModalConfirm
          handleClose={() => setIsDownloadModalOpen(false)}
          opened={isDownloadModalOpen}
          title="Send warehouse status export via e-mail?"
          message={`The warehouse status export will be sent to ${stateUserLevel.username} via email. The process may take a few minutes.`}
          onConfirm={() => {
            setIsDownloadModalOpen(false);
            sendWarehouseExportEmail();
          }}
        />
      )}
    </>
  );
};
