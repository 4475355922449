import { Card, LinearProgress } from '@mui/material';
import {
  DataGridPremium,
  GridInitialState,
  GridLogicOperator,
  GridSlots,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { GridPremiumToolbarButton } from 'common/Tables/toolbar/GridPremiumToolbarButton';
import { SearchField } from 'udb/inventory/features/premium-grid/features/search-field/SearchField';
import LZString from 'lz-string';
import { premiumGridStyles } from 'udb/inventory/features/premium-grid/premiumGrid.styles';
import { getGridRowHeight } from 'udb/inventory/features/premium-grid/utils/getGridRowHeight.util';
import { onLoadCustomGrid } from 'udb/inventory/features/premium-grid/utils/onLoadCustomGrid.util';
import { onSaveCustomGrid } from 'udb/inventory/features/premium-grid/utils/onSaveCustomGrid.util';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { useFacilityModalsStore } from 'store/Modals';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useParams, useSearchParams } from 'react-router-dom';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { ILocationDataST } from 'codegen/report';
import { cleanGridState } from 'udb/inventory/features/premium-grid/utils/cleanGridState.util';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { usePrevious } from 'hooks/usePrevious';
import { openLocationModal } from 'udb/inventory/features/premium-grid/utils/openLocationModal.util';
import { getFilteredLocationData } from 'udb/inventory/features/premium-grid/utils/getFilteredLocationData.util';
import { calculateTabCounts } from 'udb/inventory/features/premium-grid/utils/calculateTabCounts';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { onRemoveCustomGrid } from 'udb/inventory/features/premium-grid/utils/onRemoveCustomGrid.util';
import { verifyCustomGridStateAvailable } from 'udb/inventory/features/premium-grid/utils/verifyCustomGridStateAvailable.utils';
import { decodeGridStateFromUrl } from 'common/Tables/tableFunctions';
import { getLabelOptionsFromData } from 'udb/inventory/features/premium-grid/utils/getLabelOptionsFromData';
import { getIssueValueOptionsFromData } from 'udb/inventory/features/premium-grid/utils/getIssueValueOptionsFromData';
import { getIssueStateValueOptionsFromData } from 'udb/inventory/features/premium-grid/utils/getIssueStateValueOptionsFromData';
import { searchFieldColumns } from '../../models/searchFieldColumns.model';
import { getWarehouseStatusTabs } from '../../models/WarehouseStatusBetaTabs.model';
import { getColumns } from '../../models/WHSColumns.model';
import { WarehouseStatusBetaTabs } from '../tabs/WarehouseStatusBetaTabs';
import { WarehouseStatusBetaToolbar } from '../toolbar/WarehouseStatusBetaToolbar';
import { SnoozeIssuesModal } from '../snooze-issues-modal/SnoozeIssuesModal';
import { serializedInitialGridState } from '../../defaults/serializedInitialGridState.default';
import { useLocationData } from '../../hooks/useLocationData';
import { useStyles } from './WarehouseStatusBetaTable.styles';

const STARTING_TAB = 1;
const ACTIVE_TAB_KEY = 'activeTab';

export function WarehouseStatusBetaTable({ gridState }: { gridState?: GridInitialStatePremium }) {
  const { systemId = '' } = useParams();

  const { locationData, loadLocationData, isLoadingLocations } = useLocationData(systemId);
  const { cx, classes } = useStyles();

  const gridApiRef = useGridApiRef();

  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();

  const { stateFacilityLevel } = useFacilityLevelStore();

  const { facilitySettings } = stateFacilityLevel;

  const [searchParams, setSearchParams] = useSearchParams();

  const [tabCounts, setTabCounts] = useState<{
    [key: string]: number;
  }>({});

  const dataGridStateFromURL = searchParams.get('dataGridState');
  const [gridViewFromURL] = useState<GridInitialState>(
    decodeGridStateFromUrl(dataGridStateFromURL),
  );

  const showAmended =
    userHasPermission(PERMISSION.AMEND_REPORT) && facilitySettings.allow_user_amend;
  const [activeTab, setActiveTab] = useState<number>(
    Number(searchParams.get(ACTIVE_TAB_KEY) ?? STARTING_TAB),
  );
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false);
  const [snoozeAction, setSnoozeAction] = useState(ISSUE_ACTIONS.SNOOZE);
  const [serializedGridState, setSerializedGridState] = useState('');
  const [isLoadViewDisabled, setIsLoadViewDisabled] = useState(
    verifyCustomGridStateAvailable({ gridName: 'warehouse-status', activeTab }),
  );

  const rowCount = locationData.length;

  const warehouseStatusTabs = useMemo(
    () =>
      getWarehouseStatusTabs({
        isUserAmendAllowed: !!facilitySettings?.allow_user_amend,
        areLabelsShown: !!facilitySettings.show_location_labels,
      }),
    [facilitySettings?.allow_user_amend, facilitySettings.show_location_labels],
  );

  const handleTabChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      setActiveTab(newValue);

      setIsLoadViewDisabled(
        verifyCustomGridStateAvailable({ gridName: 'warehouse-status', activeTab: newValue }),
      );

      const { gridState } = warehouseStatusTabs[newValue];
      gridApiRef.current.restoreState({ ...gridState });

      searchParams.set(ACTIVE_TAB_KEY, newValue.toString());
      setSearchParams(searchParams);
    },
    [gridApiRef, searchParams, setSearchParams, warehouseStatusTabs],
  );

  useEffect(() => {
    setSearchParams((previousSearchParams) => {
      previousSearchParams.set(ACTIVE_TAB_KEY, String(activeTab));
      return previousSearchParams;
    });
    // Intentionally disabled to only run once when component mounts [TC]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredLocationList = useMemo(
    () =>
      getFilteredLocationData(
        locationData,
        warehouseStatusTabs[activeTab].issueTypeFilters,
        warehouseStatusTabs[activeTab].issueStateFilters,
        warehouseStatusTabs[activeTab].slotSettingFilter,
        warehouseStatusTabs[activeTab]?.filter,
      ),
    [locationData, activeTab, warehouseStatusTabs],
  );

  const locationLabelOptions = useMemo(
    () => getLabelOptionsFromData(filteredLocationList),
    [filteredLocationList],
  );

  const issueValueOptions = useMemo(
    () => getIssueValueOptionsFromData(filteredLocationList, activeTab),
    [activeTab, filteredLocationList],
  );

  const issueStatusOptions = useMemo(
    () => getIssueStateValueOptionsFromData(filteredLocationList, activeTab),
    [activeTab, filteredLocationList],
  );

  const columns = useMemo(
    () => getColumns(facilitySettings, locationLabelOptions, issueValueOptions, issueStatusOptions),
    [facilitySettings, locationLabelOptions, issueValueOptions, issueStatusOptions],
  );

  useEffect(() => {
    const locationFromUrl = searchParams.get('location');
    if (locationFromUrl && !isLoadingLocations) {
      openLocationModal(
        locationFromUrl,
        filteredLocationList,
        dispatchFacilityModals,
        'WarehouseStatus',
        loadLocationData,
        locationData,
      );
    }
    const searchActiveTab = Number(searchParams.get(ACTIVE_TAB_KEY));
    setActiveTab(searchActiveTab);
  }, [
    dispatchFacilityModals,
    isLoadingLocations,
    loadLocationData,
    locationData,
    searchParams,
    filteredLocationList,
  ]);

  // NOTE: this is used later in a useEffect to allow additional changes in the filters, columns, etc.
  // while a gridState prop is passed (e.g. when clicking one of the header's chart)
  // eno: 2024-09-02
  const previousGridState = usePrevious(gridState) as GridInitialStatePremium | undefined;

  useEffect(() => {
    // NOTE: the latter part of the if statement avoids to reapply the grid state
    // passed as a prop when the table filtering/columns/etc. has been altered manually
    // eno: 2024-09-02
    if (gridState && gridState !== previousGridState) {
      handleTabChange(null as unknown as ChangeEvent<HTMLInputElement>, 0);
      const { gridState: fullTableState } = warehouseStatusTabs[0];
      gridApiRef.current.restoreState({ ...fullTableState, ...gridState });
    } else if (activeTab.toString() !== searchParams.get('activeTab')) {
      // NOTE: we trigger this handleTabChange when this gets changed in the url by
      // navigate, e.g.: when clicking the link to the labeled tab in the Location Modal
      // eno: 2024-10-18 - see also https://verity-ag.atlassian.net/browse/PI-1357
      const activeTabFromUrl = searchParams.get('activeTab');
      if (activeTabFromUrl) {
        const newActiveTab = parseInt(activeTabFromUrl, 10);
        handleTabChange(null as unknown as ChangeEvent<HTMLInputElement>, newActiveTab);
      }
    }
  }, [
    activeTab,
    gridApiRef,
    gridState,
    handleTabChange,
    previousGridState,
    searchParams,
    warehouseStatusTabs,
  ]);

  useEffect(() => {
    setTabCounts(
      calculateTabCounts({
        locationDataList: locationData,
        countAmended: showAmended,
        countLabeled: !!facilitySettings.show_location_labels,
      }),
    );
  }, [facilitySettings.show_location_labels, locationData, rowCount, showAmended]);

  const tableToolbar = () => (
    <WarehouseStatusBetaToolbar
      checkboxSelection={checkboxSelection}
      setCheckboxSelection={setCheckboxSelection}
      onSnooze={() => {
        setSnoozeAction(ISSUE_ACTIONS.SNOOZE);
        setIsSnoozeModalOpen(true);
      }}
      onUnSnooze={() => {
        setSnoozeAction(ISSUE_ACTIONS.UNSNOOZE);
        setIsSnoozeModalOpen(true);
      }}
      allowPrint={warehouseStatusTabs[activeTab].label === 'LABELED'}
    />
  );

  const handleGridStateChange = () => {
    const currentGridState = cleanGridState(gridApiRef.current.exportState());
    const currentSerializedGridState = JSON.stringify(currentGridState);

    if (serializedGridState !== currentSerializedGridState) {
      setSerializedGridState(currentSerializedGridState);
    }
  };

  useEffect(() => {
    if (serializedGridState === serializedInitialGridState || serializedGridState === '') {
      return;
    }
    const urlState = searchParams.get('dataGridState');
    const urlStateDecompressed = urlState
      ? LZString.decompressFromEncodedURIComponent(urlState)
      : null;

    if (serializedGridState !== urlStateDecompressed) {
      const compressedState = LZString.compressToEncodedURIComponent(serializedGridState);
      searchParams.set('dataGridState', compressedState);
      setSearchParams(searchParams);
    }
  }, [searchParams, serializedGridState, setSearchParams]);

  const selectedRows = () =>
    Array.from(gridApiRef.current.getSelectedRows(), ([_id, value]) => value) as ILocationDataST[];

  return (
    <>
      {!facilityModalsState.locationModalOpened && (
        <Card>
          <div className={classes.cardHeader}>
            <WarehouseStatusBetaTabs
              tabs={warehouseStatusTabs}
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              tabCounts={tabCounts}
            />

            <div className={cx(classes.cardHeaderSection, classes.cardHeaderActions)}>
              <GridPremiumToolbarButton
                name="SAVE VIEW"
                tooltip="Save View"
                onClick={() => {
                  onSaveCustomGrid({ gridName: 'warehouse-status', activeTab, gridApiRef });
                  setIsLoadViewDisabled(true);
                }}
                icon={<BookmarkAddIcon />}
              />

              <GridPremiumToolbarButton
                name="LOAD VIEW"
                tooltip={
                  isLoadViewDisabled
                    ? 'Click to load a view for this tab'
                    : 'There are no saved views for this tab'
                }
                disabled={!isLoadViewDisabled}
                onClick={() =>
                  onLoadCustomGrid({ gridName: 'warehouse-status', activeTab, gridApiRef })
                }
                icon={<BookmarkIcon />}
              />

              {isLoadViewDisabled && (
                <GridPremiumToolbarButton
                  name="REMOVE VIEW"
                  tooltip="Remove view (revert back to default)"
                  onClick={() => {
                    onRemoveCustomGrid({ gridName: 'warehouse-status', activeTab });
                    setIsLoadViewDisabled(false);
                  }}
                  icon={<BookmarkRemoveIcon />}
                />
              )}
            </div>

            <div className={classes.cardHeaderSection}>
              <SearchField
                columns={searchFieldColumns}
                isDisabled={!gridApiRef.current}
                onChange={(items) =>
                  gridApiRef.current.setFilterModel({
                    items,
                    logicOperator: GridLogicOperator.Or,
                  })
                }
              />
            </div>
          </div>

          <div className={classes.cardBody}>
            <DataGridPremium
              sx={premiumGridStyles}
              columns={columns}
              apiRef={gridApiRef}
              rows={filteredLocationList}
              getRowId={(row) => row.slot_label}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
              }
              getRowHeight={getGridRowHeight}
              columnHeaderHeight={45}
              slots={{
                toolbar: tableToolbar,
                loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
              }}
              loading={isLoadingLocations}
              onRowClick={(params) => {
                openLocationModal(
                  params.row.slot_label,
                  filteredLocationList,
                  dispatchFacilityModals,
                  'WarehouseStatus',
                  loadLocationData,
                  locationData,
                );
                searchParams.set('location', params.row.slot_label);
                setSearchParams(searchParams);
              }}
              localeText={{
                // @ts-expect-error customising types for localeText is not fully supported currently. https://github.com/mui/mui-x/blob/HEAD/packages/x-data-grid/src/constants/localeTextConstants.ts
                headerFilterOperatorNotContains: 'not contain',
                headerFilterOperatorIsNotAnyOf: 'is not any of',
                headerFilterOperatorIsNot: 'is not',
              }}
              checkboxSelection={checkboxSelection}
              rowGroupingColumnMode="multiple"
              disableRowSelectionOnClick
              disableAggregation
              headerFilters
              headerFilterHeight={60}
              onStateChange={handleGridStateChange}
              initialState={
                gridViewFromURL
                  ? { ...gridViewFromURL }
                  : {
                      ...warehouseStatusTabs[activeTab].gridState,
                    }
              }
            />
          </div>
        </Card>
      )}

      {isSnoozeModalOpen && (
        <SnoozeIssuesModal
          locations={selectedRows()}
          action={snoozeAction}
          onClose={() => setIsSnoozeModalOpen(false)}
          refreshData={loadLocationData}
        />
      )}
    </>
  );
}
