import { DISPLAY_ISSUE_TYPES_KEYS } from 'common/issueTypesAndStates';
import { IInventoryRequestST, IIssue1STStateEnum } from 'codegen/report';
import { IFacilityModalsState, ModalData } from './IFacilityModalsStore';
import { IFacilityModalsAction } from './IFacilityModalsActions';

const DEFAULT_DATA: ModalData = {
  bodyText: '',
  noLabel: '',
  onNoFunction: () => ({}),
  onCloseDispatch: {} as IFacilityModalsAction,
  reportId: '',
  subtitle: '',
  title: '',
  yesCTA: false,
  yesLabel: '',
  id: '',
  reportIds: [],
  confirmationWord: '',
};

/**
 * Initial state of the facility modal store
 */
export const initialFacilityModalsState: IFacilityModalsState = {
  editRequestModalOpened: false,
  deleteRequestModalOpened: false,
  toggleRequestStateModalOpened: false,
  toggleSnoozeStateModalOpened: false,
  amendModalOpened: false,
  overwriteData: [
    {
      userOverride: undefined,
      location: '',
      version: undefined,
      verityValue: [],
      wmsState: undefined,
      wmsValue: '',
      slotStatusVersion: undefined,
      isReview: false,
      userOverrideValue: [],
      verityAmended: '',
      originalVerityState: undefined,
      originalVerityValue: '-',
      showContentSetByUser: false,
      imageIds: [],
      reportId: '',
      verityDate: '',
      length: 0,
      selected: false,
      userOverrideUserName: '',
    },
  ],
  locationModalOpened: false,
  requestToEdit: {} as IInventoryRequestST,
  refreshData: {},
  abortReportModalOpened: false,
  abortReportModalData: DEFAULT_DATA,
  archiveReportModalOpened: false,
  archiveReportModalData: DEFAULT_DATA,
  restoreReportModalOpened: false,
  restoreReportModalData: DEFAULT_DATA,
  updateReportModalOpened: false,
  updateReportModalData: DEFAULT_DATA,

  resetSystemModalOpened: false,
  resetSystemData: DEFAULT_DATA,

  droneErrorModalOpened: false,
  droneErrorData: {
    id: -1,
    drone_name: '-1',
    serial: '0000000',
    chargerId: '',
    chargerPosition: '',
    battery_level: '',
    battery_state: '',
    wifiStatus: '',
    drone_state: 'ON_CHARGER',
    drone_online: 'OFFLINE',
    last_operation_feedback: '',
    not_ready_reason: '',
    additional_diagnostics: '',
    updatedAt: '',
  },

  deleteReportSpecModalOpened: false,
  deleteReportSpecData: DEFAULT_DATA,
  locations: [
    {
      userOverride: undefined,
      location: '',
      verityValue: '',
      wmsValue: '',
      isReview: false,
      userOverrideValue: [],
      issueType: DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED,
      id: '',
      imageIds: [],
      rowData: {
        actions: {
          actions: [],
        },
        state: IIssue1STStateEnum.New,
        contentFoundDate: '',
        matchArray: [],
        wmsDate: '',
        customer: '',
        LocationName: '',
        barcodeExpectedFoundOn: '',
        barcodeFoundShouldBeOn: '',
        wmsSlotStatusVersion: null,
        contentExpected: [],
        contentFound: [],
        issueId: '',
        slotStatus: {
          verity_status: {
            result_id: '',
            slot_label: '',
            state: 'EMPTY',
            pending_backup: false,
            collected_at: '',
            barcodes: [],
            image_ids: [],
            next_scheduled_at: null,
            user_overrides: null,
            version: 0,
            staging_version: null,
            updated_at: '',
            approved: false,
            approved_by: null,
          },
        },
        wmsArticleNo: [],
        wmsCustomer: [],
        wmsQty: [],
      },
    },
  ],
};
