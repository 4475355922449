import { ImageList, ImageListItem } from '@mui/material';
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isTextAreaOrInputActive } from 'common/functions/domFunctions';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { BarcodeMatchLogic } from 'common/functions/barcodes/getBarcodeMuiColor.util';
import { FullSizeImageModal } from './full-size-image-modal/FullSizeImageModal';
import { ImageGridItem } from './ImageGridItem';
import { NUMBER_OF_CONCURRENT_IMAGES_TO_DISPLAY, useImageGridStyles } from './ImageGrid.styles';

export const ImageGrid = ({
  imageUrls,
  locationData,
  systemId,
  showBarcodeHighlighting,
  highlightedBarcode,
  issueLogic,
  barcodeMatchLogic,
  setHighlightedBarcode,
}: {
  imageUrls: string[];
  locationData: ILocationData;
  systemId: string;
  showBarcodeHighlighting: boolean;
  highlightedBarcode: string;
  issueLogic: IRuleActionSTIssueLogicEnum | undefined;
  barcodeMatchLogic: BarcodeMatchLogic | undefined;
  setHighlightedBarcode: Dispatch<SetStateAction<string>>;
}) => {
  const { classes } = useImageGridStyles();

  const [imagePointerVisible, setImagePointerVisible] = useState(false);
  const imageListContainer = useRef<HTMLUListElement>(null);
  const imageListItemRefs = useRef<HTMLLIElement[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isFullSizeImageModalOpen, setIsFullSizeImageModalOpen] = useState(false);

  useEffect(() => {
    const imageToScrollTo = imageListItemRefs.current[selectedImageIndex];
    imageListContainer?.current?.scrollTo({
      left: imageToScrollTo?.offsetLeft,
      behavior: 'smooth',
    });
  }, [selectedImageIndex]);

  const scrollToPrevImage = useCallback(() => {
    const prev = Math.max(selectedImageIndex - 1, 0);
    setSelectedImageIndex(prev);
  }, [selectedImageIndex]);

  const scrollToNextImage = useCallback(() => {
    const next = Math.min(
      selectedImageIndex + 1,
      isFullSizeImageModalOpen
        ? imageUrls.length - 1
        : imageUrls.length - NUMBER_OF_CONCURRENT_IMAGES_TO_DISPLAY,
    );
    setSelectedImageIndex(next);
  }, [selectedImageIndex, isFullSizeImageModalOpen, imageUrls.length]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isTextAreaOrInputActive()) return;

      if (event.code === 'ArrowUp') scrollToPrevImage();
      if (event.code === 'ArrowDown') scrollToNextImage();
      if (event.code === 'Period' || event.code === 'NumpadDecimal') {
        setSelectedImageIndex(0);
        setIsFullSizeImageModalOpen(true);
      }

      if (event.altKey) {
        setImagePointerVisible(true);
      }
    },
    [scrollToPrevImage, scrollToNextImage],
  );

  const handleKeyUp = useCallback(() => {
    setImagePointerVisible(false);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  useEffect(() => {
    setSelectedImageIndex(0);
  }, [imageUrls]);

  const handleImageClick = (e: MouseEvent & { altKey: boolean }, index: number) => {
    if (e.altKey) {
      setSelectedImageIndex(index);
      setIsFullSizeImageModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    if (selectedImageIndex > imageUrls.length - NUMBER_OF_CONCURRENT_IMAGES_TO_DISPLAY) {
      setSelectedImageIndex(imageUrls.length - NUMBER_OF_CONCURRENT_IMAGES_TO_DISPLAY);
    }
    setIsFullSizeImageModalOpen(false);
  };

  return (
    <>
      <ImageList className={classes.imageListContainer} gap={8} ref={imageListContainer}>
        {imageUrls.map((imageURL, index) => (
          <ImageListItem
            className={`${classes.imageListItem} ${imagePointerVisible ? 'alt-pressed' : ''}`}
            key={imageURL}
            ref={(el: HTMLLIElement) => (imageListItemRefs.current[index] = el)}
            onClick={(e: MouseEvent) => handleImageClick(e, index)}
          >
            <ImageGridItem
              index={index}
              imageURL={imageURL}
              locationData={locationData}
              highlightedBarcode={highlightedBarcode}
              barcodeMatchLogic={barcodeMatchLogic}
              issueLogic={issueLogic}
              setHighlightedBarcode={setHighlightedBarcode}
              showBarcodeHighlighting={showBarcodeHighlighting}
              systemId={systemId}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <FullSizeImageModal
        imageUrls={imageUrls}
        isModalOpen={isFullSizeImageModalOpen}
        selectedImageIndex={selectedImageIndex}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};
