import { CameraControls } from '@react-three/drei';
import { Vector3 } from '@react-three/fiber';
import CameraControlsLib from 'camera-controls';
import { RefObject } from 'react';
import { useCameraFrustumSizing } from 'shared/map-3d/aisle-view/hooks/useCameraFrustumSizing';
import { useZoomClamp } from 'shared/map-3d/aisle-view/hooks/useZoomClamp';
import { useZoomFit } from 'shared/map-3d/aisle-view/hooks/useZoomFit';
import { vectorLike2Vector } from 'shared/map-3d/vectorLike2Vector.util';
import { Group } from 'three';

const LOCK_ANGLE = Math.PI / 2;

export const AisleViewScene = ({
  aisleRef,
  fit = { type: 'width' },
  current,
}: {
  aisleRef: RefObject<Group>;
  fit?: { type: 'height' | 'width' };
  current?: {
    normal: Vector3;
    position: Vector3;
  };
}) => {
  useCameraFrustumSizing();
  useZoomFit({
    aisleRef,
    current: current
      ? {
          normal: vectorLike2Vector(current.normal),
          position: vectorLike2Vector(current.position),
        }
      : undefined,
    fit,
  });
  useZoomClamp(aisleRef);
  return (
    <CameraControls
      makeDefault
      verticalDragToForward
      boundaryEnclosesCamera
      smoothTime={0.75}
      mouseButtons={{
        left: CameraControlsLib.ACTION.TRUCK,
        right: CameraControlsLib.ACTION.NONE,
        middle: CameraControlsLib.ACTION.NONE,
        wheel: CameraControlsLib.ACTION.NONE,
      }}
      minPolarAngle={LOCK_ANGLE}
      maxPolarAngle={LOCK_ANGLE}
    />
  );
};
