import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { Box } from 'components/common/Box';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Button, Theme } from '@mui/material';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { IssueCell } from 'udb/inventory/features/premium-grid/features/cell';
import { useLocationData } from 'udb/inventory/features/warehouse-status-beta/hooks/useLocationData';
import { issueTypeEnumToStringMap } from 'common/functions/issues/issueColorFunctions';
import { LocationSummaryPopupProps } from './LocationSummaryPopup.model';

export const LocationSummaryPopup: React.FC<LocationSummaryPopupProps> = ({
  locationName,
  onClose,
  details,
}) => {
  const location = useLocation();

  const { currentSystemId } = useFacilityLevelStore().stateFacilityLevel;

  const { locationDataMap } = useLocationData(currentSystemId ?? '');
  const currentLocation = locationDataMap.get(locationName);

  const [searchParams] = useSearchParams();
  searchParams.set('location', locationName);
  // Need this until PI-1087 is resolved [TC]
  searchParams.set('activeTab', '0');
  const issueType = currentLocation?.issues?.[0]?.type;
  const issueDisplayValue = issueType ? issueTypeEnumToStringMap[issueType] : 'No Issue';

  // https://verity-ag.atlassian.net/browse/GC-1737
  return (
    <Stack
      sx={(theme: Theme) => ({
        width: 300,
        gap: 1.5,
        padding: 2,
        borderTopRightRadius: theme.shape.borderRadius * 2,
        borderBottomRightRadius: theme.shape.borderRadius * 2,
        border: `1px solid ${theme.palette.divider}`,
        height: '100%',
      })}
    >
      <Stack
        sx={{
          minWidth: 240,
          flexDirection: 'row',
          alignItems: 'baseline',
        }}
      >
        <Stack>
          <Typography variant="caption">Location number</Typography>
          <Typography variant="h6">{locationName}</Typography>
        </Stack>
        <IconButton
          aria-label="close"
          sx={{
            marginLeft: 'auto',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Button
        variant="outlined"
        size="small"
        sx={{
          alignSelf: 'baseline',
        }}
        component={Link}
        to={{
          ...location,
          pathname: `/${currentSystemId}${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X}`,
          search: searchParams.toString(),
        }}
      >
        View Location
      </Button>
      <Divider variant="middle" sx={{ margin: 0 }} />
      <Stack>
        <Typography variant="caption" gutterBottom>
          Location Issue
        </Typography>
        <Stack direction="row">
          <IssueCell round issueType={issueDisplayValue} />
        </Stack>
      </Stack>
      {details ? (
        <>
          <Divider variant="middle" sx={{ margin: 0 }} />
          <Typography variant="caption">Barcode Issue</Typography>
          {details.map(({ title, color, items }) => (
            <Stack gap={1} key={title}>
              <Stack direction="row" alignItems="baseline" gap={1}>
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    backgroundColor: color,
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                  }}
                />
                <Typography variant="body2">{title}</Typography>
              </Stack>
              <Stack gap={1} flexDirection="row" flexWrap="wrap">
                {items.map((item) => (
                  <Box
                    key={item}
                    sx={(theme) => ({
                      borderRadius: 1,
                      backgroundColor: theme.palette.grey['100'],
                      padding: 1,
                    })}
                  >
                    <Typography>{item}</Typography>
                  </Box>
                ))}
              </Stack>
            </Stack>
          ))}
        </>
      ) : null}
    </Stack>
  );
};

LocationSummaryPopup.displayName = 'LocationSummaryPopup';
