import { Bin3DContainer } from 'shared/map-3d/bin3D/Bin3D';

import { forwardRef, Ref } from 'react';
import { Group } from 'three';
import { useParams } from 'react-router-dom';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { findNodesByType } from 'shared/map-3d/map-node.util';
import { vectorLike2Vector } from 'shared/map-3d/vectorLike2Vector.util';
import { NodeST } from 'shared/map-container/MapContainer.model';
import { ActiveAisle } from '../active-aisle/ActiveAisle';
import { useRackTops } from './hooks/useRackTops';

const HIT_TARGET_HEIGHT = 0.1;

function useRackHitTargets({
  topZ,
  selectedFlightDomain,
}: {
  topZ: number;
  selectedFlightDomain: string;
}) {
  const { systemId = '' } = useParams();
  const { data: facilityMap, isLoading: isLoadingMap } = useFacilityMap(systemId);

  if (isLoadingMap || !facilityMap) {
    return [];
  }

  const selectedFlightDomainNode = facilityMap.nodes?.find(
    (node) => node.name === selectedFlightDomain,
  );

  if (!selectedFlightDomainNode) {
    return [];
  }

  const aisleSides = findNodesByType(selectedFlightDomainNode, 'AISLE_SIDE');
  return aisleSides.map(({ position, scale, ...side }) => ({
    ...side,
    position: vectorLike2Vector(position).setZ(topZ + HIT_TARGET_HEIGHT),
    scale: vectorLike2Vector(scale).setZ(0.1),
  }));
}

export const TopViewMap = forwardRef(
  (
    {
      handleSelected,
      selectedAisleId,
      selectedFlightDomain,
    }: {
      selectedFlightDomain: string;
      handleSelected: (aisleSide: NodeST) => void;
      selectedAisleId?: string;
    },
    ref: Ref<Group>,
  ) => {
    const rackTopView = useRackTops(selectedFlightDomain);
    const hitTargets = useRackHitTargets({ topZ: rackTopView.maxHeightZ, selectedFlightDomain });

    return rackTopView.isLoading ? null : (
      <>
        {hitTargets.map((aisle) => (
          <group
            key={aisle.position.toArray().join(',')}
            visible={selectedAisleId === aisle.id}
            onPointerOver={(event) => {
              event.stopPropagation();
            }}
            onClick={(event) => {
              event.stopPropagation();
              handleSelected(aisle);
            }}
          >
            <ActiveAisle position={aisle.position} scale={aisle.scale} opacity={0.08} />
          </group>
        ))}
        <Bin3DContainer ref={ref} bins={rackTopView.rackTops} />
      </>
    );
  },
);
