import { Tooltip } from '@mui/material';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import { matchBarcodes } from 'common/functions/barcodes/matchBarcode.util';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { barcodeStatusEnum } from 'components/ModalsAndPopups/LocationModal/features/barcodes/models/BarcodeRow.model';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { IVeritySlotStatusStateST } from 'codegen/report';
import { multipleBarcode } from './multipleBarcode.style';
import { BarcodeLine } from './BarcodeLine';
import { NonBarcodeLine } from './NonBarcodeLine';

export const ContentFoundCell = ({
  locationData,
  facilitySettings,
}: {
  locationData: ILocationDataST;
  facilitySettings: IFacilitySettingsST;
}) => {
  const { classes } = multipleBarcode();

  const verityStatus = locationData.verity_status as IVeritySlotStatusST | string;
  const wmsStatus = locationData.wms_status as IWMSSlotStatusST;

  if (!verityStatus || typeof verityStatus === 'string') {
    return <span className="c-data-grid-cell">{verityStatus}</span>;
  }

  const { barcodes: verityBarcodes, user_overrides: userOverrides } = verityStatus;

  const showOverrides =
    userHasPermission(PERMISSION.AMEND_REPORT) &&
    facilitySettings?.allow_user_amend &&
    !!userOverrides?.length;

  const barcodes = showOverrides
    ? userOverrides?.[userOverrides.length - 1].barcodes
    : verityBarcodes;
  const state = showOverrides
    ? userOverrides?.[userOverrides.length - 1].state
    : verityStatus.state;

  const { barcodeMatchLogic, issueLogic } = getIssueAndBarcodeMatchingLogic(
    locationData,
    facilitySettings,
  );

  const renderTooltip = () => {
    if (showOverrides) {
      const lastOverride = userOverrides[userOverrides.length - 1];
      return (
        <span>
          value overridden: <br />
          by {lastOverride.user_name} <br />
          on {lastOverride.timestamp}. <br />
          Original value:{' '}
          <b>
            {verityBarcodes.length > 0
              ? verityBarcodes.join(' ,')
              : DISPLAY_VERITY_STATES[verityStatus.state]}
          </b>
        </span>
      );
    }

    if (!barcodes.length) {
      return DISPLAY_VERITY_STATES[verityStatus.state];
    }

    return barcodes.join(', ');
  };

  const renderContent = () => {
    const { unexpected, missing } = matchBarcodes({
      expected: wmsStatus?.barcodes,
      found: verityStatus?.barcodes,
    });

    if (verityStatus.state === IVeritySlotStatusStateST.Empty) {
      if (unexpected.length !== 0 || missing.length !== 0) {
        return (
          <span>
            <NonBarcodeLine locationData={locationData} issueLogic={issueLogic}>
              {DISPLAY_VERITY_STATES[state]}
            </NonBarcodeLine>
          </span>
        );
      }
    }

    if (!barcodes.length) {
      return (
        <span>
          <NonBarcodeLine locationData={locationData} issueLogic={issueLogic}>
            <>
              {DISPLAY_VERITY_STATES[state]}
              {showOverrides ? ' *' : ''}
            </>
          </NonBarcodeLine>
        </span>
      );
    }

    const list = barcodes.map((barcode) => {
      const status = unexpected.includes(barcode)
        ? barcodeStatusEnum.Unexpected
        : barcodeStatusEnum.Match;
      return (
        <BarcodeLine
          key={barcode}
          barcode={barcode}
          barcodeStatus={status}
          barcodeMatchLogic={barcodeMatchLogic}
          issueLogic={issueLogic}
          isOverride={showOverrides}
        />
      );
    });
    return <span className={classes.verticalCenter}>{list}</span>;
  };

  return (
    <Tooltip
      title={renderTooltip()}
      className="c-data-grid-cell c-data-grid-cell-content-found"
      data-testid="data-grid-cell"
    >
      {renderContent()}
    </Tooltip>
  );
};
