import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import { BarcodeData } from './BarcodeView.model';
import { SegmentSelector } from './SegmentSelector';

export const BarcodeViewCustomToolbar = ({
  barcodes,
  setStartSlot,
}: {
  barcodes: BarcodeData[];
  setStartSlot: React.Dispatch<React.SetStateAction<number>>;
}) => (
  <GridToolbarContainer>
    <SegmentSelector barcodes={barcodes} setStartSlot={setStartSlot} />
    <GridToolbarExport />
  </GridToolbarContainer>
);
