import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams, useParams } from 'react-router-dom';
import moment from 'moment';

import Container from '@mui/material/Container';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import * as tables from 'common/Tables';
import { changeTab } from 'common/functions/navigationFunctions';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { getLocationListData } from 'common/Tables/tableFunctions';
import { Box } from 'components/common/Box';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { EnhancedTable } from 'components/EnhancedTable';
import { filterOrderTableRows } from 'components/EnhancedTable/functions/filterOrderTableRows';
import { OrderValue } from 'components/EnhancedTable/types/rows';
import { handleLocationModalOpening } from 'udb/inventory/features/reports/features/report/utils/HandleLocationModalOpening';
import { LocationProps } from 'udb/inventory/features/reports/Reports.model';
import { CardGrid } from 'components/common/CardGrid/CardGrid';
import { useRequestController, useSlots } from 'hooks';

import { warehouseStore } from 'store/WarehouseStore';
import { useSettingsStore } from 'store/Settings/settingsStore';
import { useUserLevelStore } from 'store/UserLevelStore/userLevelStore';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';

import { useFacilityModalsStore } from 'store/Modals';
import { FacilityModalsActionTypes } from 'store/Modals/types';

import { useInfoBannersStorage } from 'hooks/useInfoBannersStorage';
import { AlertComponent } from 'components/common/Alert';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { LocationReportData } from 'udb/inventory/models/LocationReportData';
import { getTabData, loadWarehouseData } from './warehouseStatusFunctions';
import { ModalConfirm } from '../../../../components/ModalsAndPopups/ModalConfirm';
import { WarehouseStatusOverviewLegacy } from './warehouse-status-overview/WarehouseStatusOverviewLegacy';

const { warehouseTabs } = tables;

const logPrefix = getLogPrefixForType('PAGE', 'WarehouseStatus');

/**
 * Warehouse status
 * @returns component
 */
export const WarehouseStatus = () => {
  const {
    WAREHOUSE_TABLE_TABS,
    WHS_ISSUES_COLUMNS,
    WHS_SOLVED_COLUMNS,
    WHS_AMENDED_COLUMNS,
    WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
    WHS_INVALID_COLUMNS,
    WHS_FULL_REPORT_COLUMNS,
  } = useSettingsStore();

  const {
    issuesTab,
    issuesSolvedTab,
    amendedTab,
    issuesSnoozedTab,
    issuesPotentialTab,
    issuesInconclusiveTab,
    issuesInvalidTab,
    fullReportTab,
  } = useMemo(() => tables.warehouseTabsMappings(WAREHOUSE_TABLE_TABS), [WAREHOUSE_TABLE_TABS]);

  const location = useLocation() as LocationProps;

  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('searchTerm') ?? '';
  const orderBy = (searchParams.get('orderBy') as keyof LocationReportData) ?? '';
  const order = (searchParams.get('order') as OrderValue) || '';
  const activeTabParam = searchParams.get('activeTab');

  const [activeTab, setActiveTab] = useState<number>(
    activeTabParam !== null ? Number(activeTabParam) : 1,
  );
  const [issues, setIssues] = useState<LocationReportData[]>([]);
  const [solved, setSolved] = useState<LocationReportData[]>([]);
  const [amended, setAmended] = useState<LocationReportData[]>([]);
  const [snoozed, setSnoozed] = useState<LocationReportData[]>([]);
  const [potential, setPotential] = useState<LocationReportData[]>([]);
  const [inconclusive, setInconclusive] = useState<LocationReportData[]>([]);
  const [invalid, setInvalid] = useState<LocationReportData[]>([]);
  const [fullReport, setFullReport] = useState<LocationReportData[]>([]);
  const [latestUpdate] = useState(''); // setting of latestUpdate will be reintroduced soon
  const [isDownloading, setIsDownloading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const navigate = useNavigate();
  const { stateUserLevel } = useUserLevelStore();
  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();
  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;

  const [tableLoadingRequestId, setTableLoadingRequestId] = useState<string[]>([]);
  const onGeneratedRequestID = (requestID: string): void => {
    setTableLoadingRequestId((ids: string[]) => [...ids, requestID]);
  };

  const { requestController } = useRequestController(logPrefix);

  const { systemId } = useParams();

  const { data: slots = [] } = useSlots(systemId || '');

  const isVisibleDownloadButton = userHasPermission(PERMISSION.EXPORT_WAREHOUSE_STATUS_VIA_EMAIL);
  const isTableLoading: boolean = useMemo(
    () => tableLoadingRequestId.some((r) => requestController.isExecuting[r]),
    [requestController.isExecuting, tableLoadingRequestId],
  );

  const hasViewLocationDetailsPermissions = userHasPermission(PERMISSION.VIEW_LOCATION_DETAILS);

  /**
   * Current tab data
   */
  const currentTabData = useMemo(
    () =>
      getTabData({
        activeTab,
        issuesTab,
        issuesSolvedTab,
        amendedTab,
        issuesSnoozedTab,
        issuesPotentialTab,
        issuesInconclusiveTab,
        issuesInvalidTab,
        fullReportTab,
        issues,
        solved,
        fullReport,
        amended,
        snoozed,
        invalid,
        inconclusive,
        potential,
        WHS_ISSUES_COLUMNS,
        WHS_SOLVED_COLUMNS,
        WHS_FULL_REPORT_COLUMNS,
        WHS_AMENDED_COLUMNS,
        WHS_INVALID_COLUMNS,
        WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
      }),
    [
      activeTab,
      issuesTab,
      issuesSolvedTab,
      amendedTab,
      issuesSnoozedTab,
      issuesPotentialTab,
      issuesInconclusiveTab,
      issuesInvalidTab,
      fullReportTab,
      issues,
      solved,
      fullReport,
      amended,
      snoozed,
      invalid,
      inconclusive,
      potential,
      WHS_ISSUES_COLUMNS,
      WHS_SOLVED_COLUMNS,
      WHS_FULL_REPORT_COLUMNS,
      WHS_AMENDED_COLUMNS,
      WHS_INVALID_COLUMNS,
      WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
    ],
  );

  /**
   * current active spinner
   */
  const currentActiveSpinner = {
    issues: isTableLoading,
    'solved issues': isTableLoading,
    amended: isTableLoading,
    'snoozed issues': isTableLoading,
    'potential issues': isTableLoading,
    'inconclusive issues': isTableLoading,
    'invalid issues': isTableLoading,
    report: isTableLoading,
  };

  /**
   * table header tabs
   */
  const tabs = warehouseTabs({
    WAREHOUSE_TABLE_TABS,
    numberOfIssues: issues.length,
    numberOfIssuesSolved: solved.length,
    numberOfAmended: amended.length,
    numberOfIssuesSnoozed: snoozed.length,
    numberOfIssuesPotential: potential.length,
    numberOfIssuesInconclusive: inconclusive.length,
    numberOfIssuesInvalid: invalid.length,
    numberOfFullReport: fullReport.length,
  });

  /**
   * Clear local state
   */
  const clearLocalState = () => {
    setIssues([]);
    setAmended([]);
    setSnoozed([]);
    setSolved([]);
    setPotential([]);
    setInconclusive([]);
    setInvalid([]);
    setFullReport([]);
  };

  /**
   * Get warehouse data
   */
  const getWarehouseData = useCallback(() => {
    loadWarehouseData({
      slots,
      logPrefix,
      requestController,
      systemId: systemId as string,
      clearLocalState,
      setIssues,
      setSolved,
      setSnoozed,
      setPotential,
      setInconclusive,
      setInvalid,
      setFullReport,
      setAmended,
      onGeneratedRequestID,
    });
  }, [requestController, slots, systemId]);

  /**
   * Send the warehouse status excel file via email
   */
  const sendWarehouseExportEmail = useCallback(() => {
    const { signal } = requestController.reserveSlotForRequest();
    console.debug(logPrefix, 'sendWarehouseExportEmail');
    setIsConfirmModalOpen(false);
    requestController.doRequest({
      request: warehouseStore.sendWarehouseExportEmail,
      requestParams: [systemId, signal],
      callbackBeforeSend: () => setIsDownloading(true),
      messageSuccess: 'The warehouse status export shall be in your inbox in a few minutes.',
      messageErrorFallback: 'The Warehouse Status could not be sent by email.',
      callbackFinally: () => setIsDownloading(false),
    });
  }, [requestController, systemId]);

  /**
   * Tab switch handler
   * @param activeTab active tab index
   * @returns changeTab function execution
   */
  const tabSwitchHandler = (activeTab: number) =>
    changeTab({
      tabValue: activeTab,
      navigate,
      state: location.state,
      setActiveTab,
    });

  /**
   * Get data
   * Function used to re-fetch the data on the page any time the user modifies a slot status
   */
  const getData = useCallback(() => {
    getWarehouseData();
  }, [getWarehouseData]);

  /**
   * Handle opening of location modal based on url params provided
   */
  const handleModalOpening = useCallback(
    () =>
      handleLocationModalOpening({
        isModalOpened: facilityModalsState.locationModalOpened,
        searchParams,
        headCells: currentTabData.headCells,
        rows: currentTabData.rows,
        dispatchFacilityModals,
        refreshData: getData,
      }),
    [
      facilityModalsState.locationModalOpened,
      searchParams,
      currentTabData.headCells,
      currentTabData.rows,
      dispatchFacilityModals,
      getData,
    ],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    getWarehouseData();
  }, [getWarehouseData]);

  useEffect(() => {
    hasViewLocationDetailsPermissions && handleModalOpening();
  }, [handleModalOpening, hasViewLocationDetailsPermissions]);

  const onRowClick = (e: KeyboardEvent, row: LocationReportData) => {
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
      payload: getLocationListData(
        filterOrderTableRows(
          currentTabData.rows,
          searchTerm,
          orderBy,
          order,
          currentTabData.headCells,
        ),
        row,
      ),
      refreshDataOnClose: {
        refreshData: getData,
      },
      parentPage: 'WarehouseStatus',
      reportId: undefined,
    });
    searchParams.set('location', row.location);
    setSearchParams(searchParams);
  };

  const handleRowClick = hasViewLocationDetailsPermissions ? onRowClick : undefined;

  const { infoBanners, updateCountingInfoBannerStatus } = useInfoBannersStorage();

  return (
    <>
      <PageHeaderSection
        title="Warehouse status"
        subtitle={`The latest information about your warehouse ${
          latestUpdate && `: updated ${moment(latestUpdate).fromNow()}`
        }`}
        onClickDownload={() => setIsConfirmModalOpen(true)}
        downloadSpinning={isDownloading}
        showDownloadBtn={isVisibleDownloadButton}
      />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <Box mb={3}>
          <AlertComponent
            isActive={
              !infoBanners?.countingIntegration.warehouseStatusSeen &&
              !!facilitySettings.enable_counting
            }
            iconMapping={{
              info: <InfoOutlinedIcon fontSize="inherit" />,
            }}
            status="info"
            message="Please note that Counting report data is not available in the Warehouse Status view."
            preventClose={false}
            handleClose={() => updateCountingInfoBannerStatus('warehouseStatusSeen', true)}
          />
        </Box>
        <CardGrid columnCount={2}>
          {systemId && <WarehouseStatusOverviewLegacy systemId={systemId} />}

          <Box sx={{ gridColumn: '1 / -1' }}>
            <EnhancedTable
              tableFor={currentTabData.tableFor}
              tableTitle={currentTabData.tableTitle}
              tableSubtitle={currentTabData.tableSubtitle}
              headCells={currentTabData.headCells}
              rows={currentTabData.rows}
              tabStatus={true}
              refreshData={{
                refreshData: getData,
              }}
              tabs={tabs}
              changeTab={tabSwitchHandler}
              startingTab={activeTab}
              currentActiveSpinner={currentActiveSpinner}
              onRowClick={handleRowClick}
              isLoading={0}
            />
          </Box>
        </CardGrid>
      </Container>

      <ModalConfirm
        handleClose={() => setIsConfirmModalOpen(false)}
        opened={isConfirmModalOpen}
        title="Send warehouse status export via e-mail?"
        message={`The warehouse status export will be sent to ${stateUserLevel.username} via email. The process may take a few minutes.`}
        onConfirm={sendWarehouseExportEmail}
      />
    </>
  );
};
