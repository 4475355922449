import { Button } from '@mui/material';
import { BarcodeData, BARCODE_VIEW_SEGMENT_SIZE } from './BarcodeView.model';

export const SegmentSelector = ({
  barcodes,
  setStartSlot,
}: {
  barcodes: BarcodeData[];
  setStartSlot: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const numberOfBarcodes = barcodes.length;
  const segments = Math.floor(numberOfBarcodes / BARCODE_VIEW_SEGMENT_SIZE);

  const links: JSX.Element[] = [];
  for (let index = 0; index < segments; index += 1) {
    links.push(
      <Button
        key={barcodes[index * BARCODE_VIEW_SEGMENT_SIZE].id}
        onClick={() => setStartSlot(index * BARCODE_VIEW_SEGMENT_SIZE)}
      >
        {barcodes[index * BARCODE_VIEW_SEGMENT_SIZE].barcode}
      </Button>,
    );
  }

  return <div>{links}</div>;
};
